import $ from 'jquery';
import 'default-passive-events';
import 'jquery-cycle2/build/jquery.cycle2';
import 'jquery-cycle2/build/plugin/jquery.cycle2.swipe.min';

function init() {
  const collection = document.querySelectorAll('.js-carousel');

  collection.forEach(container => {
    const fx = container.dataset.fx || 'scrollHorz';
    const $container = $(container);
    const $slider = $('.js-carousel--slider', $container);
    const $slides = $('.js-carousel--slide', $container);
    const $prev = $('.js-carousel--prev', $container);
    const $next = $('.js-carousel--next', $container);
    const $pager = $('.js-carousel--pager', $container);

    $slider.cycle({
      fx: fx,
      timeout: 2000,
      swipe: true,
      speed: 300,
      slides: $slides,
      prev: $prev,
      next: $next,
      pauseOnHover: true,
      hideNonActive: !container.dataset.fx,
      pagerTemplate: '<div class="carousel_pager-item"></div>',
      pagerActiveClass: '-active',
      pager: $pager,
      log: false
    });
  });
}

(function($) {
  const tx = $.fn.cycle.transitions;

  tx.cssTransition = {
    preInit: function( opts ) {
      opts.slides.addClass('cycle-slide-pre-active');
    },
    before: function( opts, curr ) {
      $(curr).removeClass('cycle-slide-pre-active');
      $(curr).addClass('cycle-slide-post-active');
      setTimeout(() => {
        $(curr).removeClass('cycle-slide-post-active');
        $(curr).addClass('cycle-slide-pre-active');
      }, 200);
    }
  };
})(jQuery);

export default init;
