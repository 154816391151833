let container;
const depth = 1200;
let settings;

function getRandomPosition(offset) {
  return Math.round((offset * Math.random() - offset/2))
}
function getRandomPositionZ(offset) {
  return Math.round(offset * Math.random())
}
function getRandomSpeed() {
  return Math.random() + 0.00001;
}

function getSettings () {
  const isDesktop =  container.offsetWidth > 767;
  return {
    particlesCount: isDesktop ? 1200 : 300,
    maxSpeed: .1
  }
}

function generateParticles () {
  const texture = new THREE.TextureLoader().load( '/images/design/star-white.png' );
  const geometry = new THREE.Geometry();
  const material = new THREE.PointsMaterial({
    map: texture,
    size: 3,
    sizeAttenuation: true,
    transparent: true,
  });

  for (let i = 0; i < settings.particlesCount; i++) {
    const vertex = new THREE.Vector3();
    vertex.x = getRandomPosition(container.offsetWidth);
    vertex.y = getRandomPosition(container.offsetHeight);
    vertex.z = getRandomPositionZ(depth);
    vertex.speed = getRandomSpeed(settings.maxSpeed);
    geometry.vertices.push(vertex);
  }

  const particles = new THREE.Points(geometry, material);
  particles.sortParticles = true;

  return particles;
}

function init(containerName) {
  container = document.getElementById(containerName);
  settings = getSettings();
  const renderer = new THREE.WebGLRenderer({
    antialias: true,
    transparent: true,
    alpha: true
  });
  renderer.setSize(container.offsetWidth, container.offsetHeight);
  renderer.setPixelRatio(window.devicePixelRatio);
  container.appendChild(renderer.domElement);

  const camera = new THREE.PerspectiveCamera(120, container.offsetWidth / container.offsetHeight, 1);
  camera.position.z = depth;

  const particles = generateParticles();
  const scene = new THREE.Scene();

  scene.add(particles);


  const animate = function () {
    requestAnimationFrame(animate);
    renderer.render(scene, camera);

    for (let i = 0; i < particles.geometry.vertices.length; i++) {
      const particle = particles.geometry.vertices[i];
      particle.z -= particle.speed;
      if (particle.z < 0) {
        particle.x = getRandomPosition(container.offsetWidth);
        particle.y = getRandomPosition(container.offsetHeight);
        particle.z = depth;
        particle.speed = getRandomSpeed(settings.maxSpeed);
      }
    }
    particles.geometry.verticesNeedUpdate = true;
  };

  animate();

  window.addEventListener( 'resize', onWindowResize, false );

  function onWindowResize(){
    camera.aspect = container.offsetWidth / container.offsetHeight;
    camera.updateProjectionMatrix();
    renderer.setSize( container.offsetWidth, container.offsetHeight );
  }
}

export default init;
