import '../../sass/application.sass';

import viewportBuggyfill from 'viewport-units-buggyfill';
import viewportBuggyfillHacks from 'viewport-units-buggyfill/viewport-units-buggyfill.hacks';
import StarTrack from './animation/star-track';
import bg from './blocks/js-bg';
import asteroids from './blocks/js-asteroid';
import ships from './blocks/js-ships';
import classToggle from './blocks/js-class-toggle';
import carousel from './blocks/js-carousel';

bg();
asteroids();
ships();
classToggle();
carousel();
viewportBuggyfill.init({
  hacks: viewportBuggyfillHacks
});

// Init Stars animation
(function (container) {
  if (!document.getElementById(container)) return false;
  StarTrack(container);
})('js-three-js-container');
