function init() {
  const objectsList = document.querySelectorAll('.js-asteroid');
  objectsList.forEach(asteroid => {
    const offset = asteroid.dataset.offset;

    window.addEventListener('scroll', () => {
      const offsetTop = window.scrollY * offset;
      asteroid.style.transform = `translateY(${offsetTop}px)`;
    })
  });
}

export default init;
