function init() {
  const ships = document.querySelectorAll('.js-ships');

  ships.forEach(ship => {
    let speedPrev = 0;
    const setPosition = () => {
      const customSpeed = ship.dataset.speed || 1;
      const scrollTop = window.scrollY + window.innerHeight - ship.offsetTop;

      if (scrollTop > 0 && speedPrev < scrollTop) {
        const calcXTanslate =  5 - (scrollTop * .01);
        const xTanslate = calcXTanslate < -3 ? -3 : calcXTanslate;
        const calcYTanslate = (80 * customSpeed) - (scrollTop * customSpeed * .1) ;
        const yTanslate = calcYTanslate < 0 ? 0 : calcYTanslate;
        const translate = {
          x: xTanslate,
          y: yTanslate
        };
        ship.style.transform = `translate(${translate.x}%, ${translate.y}%)`;
      }
      if (scrollTop > speedPrev || scrollTop < 0) {
        speedPrev =  scrollTop;
      }
    };

    window.addEventListener('scroll', setPosition);
    setPosition();
  });
}

export default init;
