function init() {
  const collection = document.querySelectorAll('.js-class-toggle');

  collection.forEach(handler => {
    const target = handler.dataset.target;
    const className = handler.dataset.class;
    const event = handler.dataset.event || 'click';
    const action = handler.dataset.action || 'toggle';

    handler.addEventListener(event, function () {
      const targetsCollection = document.querySelectorAll(target);
      targetsCollection.forEach(currentTarget => {
        currentTarget.classList[action](className)
      });
    });
  });
}

export default init;


