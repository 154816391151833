import LazyLoad from 'lazyload';

function init() {
  const collection = document.querySelectorAll('.js-bg');

  collection.forEach(container => {
    const start = container.dataset.start;
    const end = container.dataset.end;
    const path = container.dataset.path;
    const images = [];

    for(let i = start; i <= end; i++) {
      const image = document.createElement('img');
      image.setAttribute('nopin', 'nopin');
      image.setAttribute('data-src', `${path}${i}.jpg`);
      image.setAttribute('src', 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7');
      container.appendChild(image);
      images.push(image);
    }
    new LazyLoad(images);
  });
}

export default init
